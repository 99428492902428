<template>
  <div>
    <div class="panel-sub-title">
      <div class="title">
        {{ $t("brandTypeRatio") }}
      </div>

      <div>
        <el-select
          class="cycle-select"
          v-model="payCycle"
          v-on:change="getList(1, currentPageSize, true)"
          :disabled="loading"
        >
          <el-option v-for="item in payCycleList" :value="item.value" :label="item.name" :key="item.value"></el-option>
        </el-select>
      </div>
    </div>
    <div>
      <el-table :data="payDataList" v-loading="loading">
        <el-table-column prop="payType" v-bind:label="$t('brand')" min-width="33%">
          <template slot-scope="scope">
            <div class="pay-logo">
              <img
                v-if="
                  app.paymentLogos.length > 0 && app.paymentLogos.find(item => item.displayName === scope.row.payType)
                "
                :src="app.paymentLogos.find(item => item.displayName === scope.row.payType).logos.thumbnail"
                :alt="scope.row.payType"
                data-desc="displayName"
              />
              <span v-else>{{ scope.row.payType }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="totalAmount" v-bind:label="$t('money')" min-width="33%">
          <template slot-scope="scope">
            <div>{{ moneyFormat(scope.row.amount) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="ratio" v-bind:label="$t('ratio')" min-width="25%"></el-table-column>
        <el-table-column v-bind:label="$t('comparedAmount' + payCycle)" width="150">
          <template slot-scope="scope">
            <div v-bind:class="scope.row.previousMonthAmount.startsWith('-') ? 'attenuate' : 'increase'">
              {{ scope.row.previousMonthAmount ? moneyFormat(scope.row.previousMonthAmount) : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150" prop="previousMonthRatio" v-bind:label="previousRatioColumnName">
          <template slot-scope="scope">
            <div v-bind:class="scope.row.previousMonthAmount.startsWith('-') ? 'attenuate' : 'increase'">
              {{ scope.row.previousMonthRatio }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      background
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
      class="common-pagination"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { moneyFormat } from "utils/index";
import pagination from "@/mixins/pagination";
import { payData } from "api/panel";
import { getSessionStorage } from "@/utils/storage";
export default {
  mixins: [pagination],
  data() {
    return {
      loading: true,
      btnLoading: false,
      payCycle: "Today",
      previousRatioColumnName: this.$t("comparedTheSameDayLastMonth"),
      userInfo: {},
      payCycleList: [
        {
          value: "Today",
          name: this.$t("today")
        },
        {
          value: "Yestoday",
          name: this.$t("yestoday")
        },
        {
          value: "CurrentWeek",
          name: this.$t("currentWeek")
        },
        {
          value: "LastWeek",
          name: this.$t("lastWeek")
        },
        {
          value: "CurrentMonth",
          name: this.$t("currentMonth")
        },
        {
          value: "LastMonth",
          name: this.$t("lastMonth")
        },
        {
          value: "Quarter",
          name: this.$t("quarter")
        },
        {
          value: "Year",
          name: this.$t("year")
        }
      ],
      payList: [],
      payDataList: []
    };
  },
  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    this.getList(1, this.currentPageSize);
  },
  methods: {
    moneyFormat,
    getList(pageIndex = this.currentPage, pageSize = this.currentPageSize, manually = false) {
      let that = this;
      that.currentPage = pageIndex;
      that.currentPageSize = pageSize;
      switch (that.payCycle) {
        case "CurrentWeek":
          // 本周
          that.previousRatioColumnName = this.$t("comparedTheSameWeekLastMonth");
          break;
        case "CurrentMonth":
          // 本月
          that.previousRatioColumnName = this.$t("comparedLastMonth");
          break;
        case "LastMonth":
          // 上月
          that.previousRatioColumnName = this.$t("comparedTheSameMonthLastMonth");
          break;
        case "Quarter":
          that.previousRatioColumnName = this.$t("comparedTheSameQuarterLastYear");
          break;
        case "Year":
          that.previousRatioColumnName = this.$t("comparedLastYear");
          break;
        default:
          that.previousRatioColumnName = this.$t("comparedTheSameDayLastMonth");
          break;
      }
      that.loading = true;
      payData({
        statisticsCategory: that.payCycle,
        pageIndex,
        pageSize
      }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          that.payDataList = response.result;
          that.total = response.totalCount;
        }

        if (this.$settings.gTag && manually) {
          console.log("gtag", "/api/trade/paycategoryamountstatistics");
          gtag("event", "/api/trade/paycategoryamountstatistics", {
            event_category: "API Call",
            ent_name: this.$store.state.app.userInfo.entName,
            ent_displayname: this.$store.state.app.userInfo.entDisplayName,
            user_id: this.$store.state.app.userInfo.userId,
            uuid: this.$store.state.app.userInfo.userId
          });
          // user_name: this.$store.state.app.userInfo.userName
          // user_email: this.$store.state.app.userInfo.userEmail,
        }
        that.loading = false;
      });
    }
  },
  computed: {
    ...mapState(["app"])
  }
};
</script>
